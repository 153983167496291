import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import CardMedia from '@mui/material/CardMedia';
import Main from 'layouts/Main';
import { Container } from '@mui/material';
import Cont from './Cont'
import Archive from './Archive';
import Events2023 from './Events2023';
import Events2024 from './Events2024';


const Eventss = () => {
  const theme = useTheme();
  const { mode } = theme.palette;

  return (
    <Box>
        <Main>
        <br/>
        <br/>
        <Container maxWidth={0.8} paddingTop={'0 !important'}>
        <Events2024/>
        </Container>
        <Container maxWidth={0.8} paddingTop={'0 !important'}>
        <Events2023/>
        </Container>
        <Container maxWidth={0.8} paddingTop={'0 !important'}>
        <Cont/>
        </Container>
        <Container maxWidth={0.78} marginTop={0.6} paddingTop={'0 !important'}>
        
        
         <Archive/>
         </Container>
      </Main>
    </Box>
  );
};

export default Eventss;
